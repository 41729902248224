@import "../fonts/fonts";

$black: #333;
$primary: #DF0415;
$crem: #fafafa;
$accent: #fafafa;

input,
button,
textarea {
	&:active {
		outline: none;
	}
	&:focus {
		outline: none;
	}
}

img {
	max-width: 100%;
	font-size: 0;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder{
  opacity: 1;
  font-size: 16px;
}
input::-moz-placeholder,
textarea::-moz-placeholder{
  opacity: 1;
  font-size: 16px;
}
input:-moz-placeholder,
textarea:-moz-placeholder{
  opacity: 1;
  font-size: 16px;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder{
  opacity: 1;
  font-size: 16px;
}

input:focus::-webkit-input-placeholder,textarea:focus::-webkit-input-placeholder {opacity: 0;}
input:focus::-moz-placeholder,textarea:focus::-moz-placeholder          {opacity: 0;}
input:focus:-moz-placeholder,textarea:focus:-moz-placeholder           {opacity: 0;}
input:focus:-ms-input-placeholder,textarea:focus:-ms-input-placeholder {opacity: 0;}

h2 {
	text-transform: uppercase;
	font-size: 36px;
	font-weight: 600;
}

h3 {
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 600;
}

* {
	box-sizing: border-box;
	font-family: 'Fira Sans', sans-serif;
	font-weight: 400;
}

body {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 0;
	overflow-x: hidden;
	color: $black;
}

main {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding-top: 80px; 
}

.wrapper {
	width: 100%;
	max-width: 1170px;
	padding: 0 15px;
	z-index: 1;
}

.wrapper.animated {
	opacity: 0;
}

.button {
	padding: 10px 20px;
	text-align: center;
	white-space: nowrap;
	border: none;
	border-radius: 2px;
	color: #333333;
	font-size: 18px;
	background-color: $accent;
	cursor: pointer;
	z-index: 1;

	&:hover {
		background-color: darken($accent, 5%);
	}

	&:active {
		background-color: darken($accent, 10%);
	}
}

.input-field {
	max-width: 280px;
	height: 40px;
	padding-left: 10px; 
	font-size: 16px;
	z-index: 1;
}

.word-xs {
	display: none;
}

input[type="radio"] {
	display: none;
}

input[type="radio"] + label {
	position: relative;
	padding: 0 0 0 30px;
	cursor: pointer;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 15px;
		height: 15px;
		border: 2px solid darken($crem, 15%);
		border-radius: 50%;
		background-color: #fff;
	}
}

input[type="radio"]:checked + label {
	&:before {
		content: "";
		position: absolute;
	    top: 5.5px;
	    left: 5.5px;
	    width: 8.5px;
	    height: 8.5px;
		border-radius: 50%;
		background-color: $primary;
		z-index: 1;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}


@media (max-width: 1024px) {
	.word-xs {
		display: inline-block;
		padding: 0 3px 0 5px;
		font-size: 14px;
	}
}

@import "../../components/header/header";
@import "../../components/first-screen/first-screen";
@import "../../components/about-company/about-company";
@import "../../components/road-map/road-map";
@import "../../components/features/features";
@import "../../components/calculator/calculator";
@import "../../components/answers/answers";
@import "../../components/portfolio/portfolio";
@import "../../components/reviews/reviews";
@import "../../components/up/up";
@import "../../components/footer/footer";
@import "../../components/popup/popup";
@import "../../../node_modules/jquery-form-styler/dist/jquery.formstyler";
@import "animate";