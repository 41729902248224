.about-company {
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
     	padding: 60px 0 100px 0;
	}

	&__title {
		width: 100%;
		margin-bottom: 70px; 
		text-align: center;
	}

	&__left {
		width: calc(35% - 10px);
	}

	&__right {
		width: calc(65% - 10px);
	}

	&__txt {
		padding: 15px;
		line-height: 1.5;
    	font-weight: 300;
    	background-color: $crem;
	}		

	&__footer {
		display: flex;
		align-items: flex-start;
		margin-top: 20px;
		padding: 20px;
		background-color: $crem;
	}
}

.workers {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 17.5px 15px 0 15px;
	text-align: center;
	border: 8px solid $crem;

	&__title {
		width: 100%;
		margin: 0 0 15px 0;
	}

	&__item {
		margin: 0 0 20px 0;
		text-align: center;
	}

	&__pic {
		display: inline-block;
		width: 250px;
		border-radius: 2px;
		overflow: hidden;
		font-size: 0;
	}

	&__position {
		margin: 10px 0 20px 0;
		text-transform: uppercase;
		font-weight: 500;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			bottom: -12.5px;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 25%;
			height: 4px;
			background-color: $primary;
		}
	}

	&__name {
		padding: 0 25px;
		font-size: 14px;
	}
}

.facts {
	display: flex;
	flex-wrap: wrap;
	margin: 20px 0 0 0;
    border: 8px solid $crem;

	&__title {
		width: 100%;
    	text-align: center;
	}

	&__item {
		width: 25%;
    	margin: 20px 0;
    	padding: 90px 10px 0 10px;
		text-align: center;
		font-size: 14px;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
		    width: 75px;
		    height: 75px;
		    border-radius: 2px;
		    background-color: $crem;
		    background-size: 50%;
		    background-repeat: no-repeat;
		    background-position: center;
		}

		&--experence {
			
			&:before {
				background-image: url(images/components/about-company/cup.svg);
			}
		}

		&--collective {
			
			&:before {
				background-image: url(images/components/about-company/users.svg);
			}
		}

		&--cars {
			
			&:before {
				background-image: url(images/components/about-company/truck.svg);
			}
		}

		&--storage {
			
			&:before {
				background-image: url(images/components/about-company/umbrella.svg);
			}
		}
	}
}

.about-company-form {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	text-align: center;

	&__trigger {
		display: inline-block;
		max-width: 480px;
	    margin: 0 0 22px 0;
	    text-align: center;
	    font-size: 20px;
	    font-weight: 600;
	}

	&__input {
		width: 100%;
		max-width: 100%;
		height: 40px;
		margin: 0 0 15px 0;

		&:focus {
			border: 1px solid $primary;
		}
	}

	&__button {
		width: 100%;
		height: 40px;
		color: #fff;
		background-color: $primary;

		&:hover {
			background-color: darken($primary, 5%);
		}

		&:active {
			background-color: darken($primary, 10%);
		}
	}
}

@media (max-width: 1024px) {
	.facts {

		&__item {
			width: 50%;
		}
	}

	.about-company-form {

		&__trigger {
			font-size: 16px;
		}
	}

	.callback-pic {
    	min-width: 205px;
	}
}

@media (max-width: 768px) {
	.about-company {
		
		&__wrapper {
			padding: 40px 0;
		}

		&__left {
			width: 100%;
		}

		&__right {
			width: 100%;
			margin-top: 20px;
		}

		&__footer {
			padding: 15px;
		}
	}

	.workers {
		justify-content: space-around;
		padding: 30px 15px 15px 15px;

		&__title {
			margin: 0 0 30px 0;
		}

		&__item {
			margin: 0 7.5px 15px 7.5px;
		}
	}

	.facts {
		padding: 0 15px 15px 15px;
	}

	.about-company-form {
		
		&__trigger {
			font-size: 15px;
		}
	}
}

@media (max-width: 480px) {
	.about-company {

		&__txt {
			text-align: center;
		}
		
		&__footer {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	
	.facts {
		&__item {
			width: 100%;
		}
	}

	.about-company-form {
		margin: 0;
		padding-bottom: 20px;
	}
}