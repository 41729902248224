.answers {
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
     	padding: 60px 0 100px 0;
	}

	&__title {
		width: 100%;
		margin-bottom: 60px; 
		text-align: center;
	}

}

.answer {
	width: 100%;
	padding: 20px 0 0 22.5px;
    border-bottom: 1px dotted darken($crem, 15%);
    position: relative;
    cursor: pointer;
    transition: 0.3s;

    &:hover {

    	.answer__title {
    		color: $primary;
    	}
    }

    &--open {
    	padding-bottom: 20px;

    	&:before {
    		transform: rotate(90deg);
    	}
    }

    &:before {
    	content: "";
    	position: absolute;
    	top: 25px;
    	left: 0;
    	width: 15px;
    	height: 15px;
    	background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
		background-image: url(images/components/answers/last-track-left-arrow.svg);
		transition: 0.3s;
    }

    &__title {
    	margin: 0 0 20px;
    }

    &__description {
    	line-height: 1.5;
    }
}