.reviews {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: $crem;
	border-bottom: 1px solid darken($crem, 5%);
	border-top: 1px solid darken($crem, 5%);

	&__wrapper {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
     	padding: 60px 0 100px 0;
	}

	&__title {
		width: 100%;
		margin-bottom: 70px; 
		text-align: center;
	}

	&__item {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		max-width: 250px;
		text-align: center;
	}

	&__name {
		width: 100%;
     	margin-bottom: 30px;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			bottom: -15px;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 25%;
			height: 4px;
			background-color: $primary;
		}
	}

	&__position {
		margin-top: 5px;
		margin-bottom: 20px;
		font-size: 14px;
	}

	&__bold {
		display: inline-block;
		width: 100%;
		margin-top: 2.5px;
		font-weight: 500;
	}

	&__pic {
		font-size: 0px;
	    border-radius: 2px;
	    overflow: hidden;
	}
}

@media (max-width: 1024px) {
	.reviews {

		&__wrapper {
			flex-direction: column;
			align-items: center;
		}

		&__item {
			margin: 15px 0;
		}
	}
}