@font-face {
			font-family: "HelveticaRegular";
			src: url("fonts/HelveticaRegular/HelveticaRegular.eot");
			src: url("fonts/HelveticaRegular/HelveticaRegular.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaRegular/HelveticaRegular.woff") format("woff"),
			url("fonts/HelveticaRegular/HelveticaRegular.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaBold";
			src: url("fonts/HelveticaBold/HelveticaBold.eot");
			src: url("fonts/HelveticaBold/HelveticaBold.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaBold/HelveticaBold.woff") format("woff"),
			url("fonts/HelveticaBold/HelveticaBold.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaItalic";
			src: url("fonts/HelveticaItalic/HelveticaItalic.eot");
			src: url("fonts/HelveticaItalic/HelveticaItalic.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaItalic/HelveticaItalic.woff") format("woff"),
			url("fonts/HelveticaItalic/HelveticaItalic.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaLight";
			src: url("fonts/HelveticaLight/HelveticaLight.eot");
			src: url("fonts/HelveticaLight/HelveticaLight.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaLight/HelveticaLight.woff") format("woff"),
			url("fonts/HelveticaLight/HelveticaLight.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaBlack";
			src: url("fonts/HelveticaBlack/HelveticaBlack.eot");
			src: url("fonts/HelveticaBlack/HelveticaBlack.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaBlack/HelveticaBlack.woff") format("woff"),
			url("fonts/HelveticaBlack/HelveticaBlack.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaHeavy";
			src: url("fonts/HelveticaHeavy/HelveticaHeavy.eot");
			src: url("fonts/HelveticaHeavy/HelveticaHeavy.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaHeavy/HelveticaHeavy.woff") format("woff"),
			url("fonts/HelveticaHeavy/HelveticaHeavy.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaMedium";
			src: url("fonts/HelveticaMedium/HelveticaMedium.eot");
			src: url("fonts/HelveticaMedium/HelveticaMedium.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaMedium/HelveticaMedium.woff") format("woff"),
			url("fonts/HelveticaMedium/HelveticaMedium.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaThin";
			src: url("fonts/HelveticaThin/HelveticaThin.eot");
			src: url("fonts/HelveticaThin/HelveticaThin.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaThin/HelveticaThin.woff") format("woff"),
			url("fonts/HelveticaThin/HelveticaThin.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaUltraLight";
			src: url("fonts/HelveticaUltraLight/HelveticaUltraLight.eot");
			src: url("fonts/HelveticaUltraLight/HelveticaUltraLight.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaUltraLight/HelveticaUltraLight.woff") format("woff"),
			url("fonts/HelveticaUltraLight/HelveticaUltraLight.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaBoldItalic";
			src: url("fonts/HelveticaBoldItalic/HelveticaBoldItalic.eot");
			src: url("fonts/HelveticaBoldItalic/HelveticaBoldItalic.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaBoldItalic/HelveticaBoldItalic.woff") format("woff"),
			url("fonts/HelveticaBoldItalic/HelveticaBoldItalic.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaBlackItalic";
			src: url("fonts/HelveticaBlackItalic/HelveticaBlackItalic.eot");
			src: url("fonts/HelveticaBlackItalic/HelveticaBlackItalic.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaBlackItalic/HelveticaBlackItalic.woff") format("woff"),
			url("fonts/HelveticaBlackItalic/HelveticaBlackItalic.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaLightItalic";
			src: url("fonts/HelveticaLightItalic/HelveticaLightItalic.eot");
			src: url("fonts/HelveticaLightItalic/HelveticaLightItalic.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaLightItalic/HelveticaLightItalic.woff") format("woff"),
			url("fonts/HelveticaLightItalic/HelveticaLightItalic.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaMediumItalic";
			src: url("fonts/HelveticaMediumItalic/HelveticaMediumItalic.eot");
			src: url("fonts/HelveticaMediumItalic/HelveticaMediumItalic.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaMediumItalic/HelveticaMediumItalic.woff") format("woff"),
			url("fonts/HelveticaMediumItalic/HelveticaMediumItalic.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaHeavyItalic";
			src: url("fonts/HelveticaHeavyItalic/HelveticaHeavyItalic.eot");
			src: url("fonts/HelveticaHeavyItalic/HelveticaHeavyItalic.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaHeavyItalic/HelveticaHeavyItalic.woff") format("woff"),
			url("fonts/HelveticaHeavyItalic/HelveticaHeavyItalic.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaThinItalic";
			src: url("fonts/HelveticaThinItalic/HelveticaThinItalic.eot");
			src: url("fonts/HelveticaThinItalic/HelveticaThinItalic.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaThinItalic/HelveticaThinItalic.woff") format("woff"),
			url("fonts/HelveticaThinItalic/HelveticaThinItalic.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}

	@font-face {
			font-family: "HelveticaUltraLightItalic";
			src: url("fonts/HelveticaUltraLightItalic/HelveticaUltraLightItalic.eot");
			src: url("fonts/HelveticaUltraLightItalic/HelveticaUltraLightItalic.eot?#iefix")format("embedded-opentype"),
			url("fonts/HelveticaUltraLightItalic/HelveticaUltraLightItalic.woff") format("woff"),
			url("fonts/HelveticaUltraLightItalic/HelveticaUltraLightItalic.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}