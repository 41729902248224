.road-map {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: $crem;
	border-bottom: 1px solid darken($crem, 5%);
	border-top: 1px solid darken($crem, 5%);

	&__wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
     	padding: 60px 0 100px 0;
	}

	&__title {
		width: 100%;
		margin-bottom: 30px; 
		text-align: center;
	}

	&__ul {
		display: flex;
    	justify-content: space-between;
    	width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		max-width: 285px;
		padding: 100px 15px 0 15px;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 75px;
			margin: 0 auto;
			background-position: 12.5px 0;
			background-repeat: no-repeat;
			background-size: 75px;
		}

		&:nth-child(1) {

			&:before {
				background-image: url(images/components/road-map/talking-by-phone-auricular-symbol-with-speech-bubble.svg);
			}
		}

		&:nth-child(2) {
			
			&:before {
				background-image: url(images/components/road-map/logistics-delivery-truck-in-movement.svg);
			}
		}

		&:nth-child(3) {
			
			&:before {
				background-image: url(images/components/road-map/packages-transportation-on-a-truck.svg);
			}
		}

		&:nth-child(4) {
			
			&:before {
				background-image: url(images/components/road-map/work-finish.svg);
			}
		}
	}

	&__arrows {
		display: flex;
	    justify-content: space-around;
	    height: 50px;
	    position: relative;
	    top: 60px;
	    width: 66.6%;
	}

	&__sub-ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	
	&__sub-item {
		margin: 0 0 15px 0;
    	padding-left: 17.5px;
		font-size: 14px;
    	line-height: 1.4;
    	position: relative;

    	&:before {
    		content: "";
    		position: absolute;
    		top: 6px;
    		left: 2.5px;
    		width: 5px;
    		height: 5px;
    		background-color: $primary;
    	}
	}
}

@keyframes showone {
  24% {opacity: 0;}
  25% {opacity: 1;}
  99% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes showtwo {
  49% {opacity: 0;}
  50% {opacity: 1;}
  99% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes showthree {
  74% {opacity: 0;}
  75% {opacity: 1;}
  99% {opacity: 1;}
  100% {opacity: 0;}
}


@media (max-width: 1024px) {
	.road-map {

		&__ul {
			flex-direction: column;
    		align-items: center;
		}

		&__item {
			margin: 15px 0;
		}
	
		&__arrows {
			display: none;
		}
	}

}