.first-screen {
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
	background-color: $crem;
	//background-position: center 10%;
	//background-repeat: no-repeat;
	//background-size: cover;
	//background-image: url(images/components/first-screen/shab3.jpg);
	overflow: hidden;
	border-bottom: 1px solid darken($crem, 5%);
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparentize($crem, 0.4);
	}

	&__wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		padding: 40px 0;
	}

	&__title {
		margin: 40px 0;
		text-align: center;
		font-family: "HelveticaBold";
    	font-size: 68px;
    	font-weight: 600;
	}

	&__top {
		display: flex;
    	justify-content: center;
    	flex-wrap: wrap;
		width: 100%;
		text-align: center;
	}

	&__middle {
		display: flex;
		flex-wrap: wrap;
	}

	&__logo {
		width: 100%;
		max-width: 325px;
		margin-right: 40px;
		text-align: center;
		font-size: 0;
	}

	&__slogan {
		display: flex;
    	justify-content: center;
		width: 100%;
		margin-top: 10px;
		font-size: 30px;
		position: relative;

		span{
			position: absolute;
			font-family: "HelveticaBold";
		}

		span:first-child {
			left: 10px;
			top: -15px;
			transform: rotate(10deg);
		}

		span:last-child {
			right: -10px;
			top: -15px;
			transform: rotate(-10deg);
		}
	}

	&__wrapper-logo-foto {
    	display: flex;
    	justify-content: space-around;
    }

    &__foto {
		height: 400px;
		border-radius: 2px;
		overflow: hidden;

    	img {
    		height: 100%;
    	}
    }

    &__time {
    	margin-top: 50px;
    	font-size: 30px;
    	font-family: "HelveticaBold";
    	color: $primary;
    }

    &__img {

    	&--lg {

    	}

    	&--xs {
			display: none;
    	}
    }
}

.trigger-form {
	display: flex;
    justify-content: center;
    flex-wrap: wrap;
	text-align: center;
    padding: 15px 15px;

	&__trigger {
		display: inline-block;
		width: 100%;
		max-width: 480px;
	    margin: 0 0 30px 0;
	    text-align: center;
	    font-size: 20px;
	    font-weight: 600;
	}

	&__wrapper {
		width: 280px;
		margin: 0 2.5%;
	}

	&__input {
		width: 100%;
		height: 40px;
		margin: 0 0 15px 0;

		&:focus {
			border: 1px solid $primary;
		}
	}

	&__button {
		width: 100%;
		height: 40px;
		margin: 0 0 15px 0;
		color: #fff;
		background-color: $primary;

		&:hover {
			background-color: darken($primary, 5%);
		}

		&:active {
			background-color: darken($primary, 10%);
		}
	}
}

.first-view-port {
	display: flex;
    flex-direction: column;
    justify-content: center;
	height: 87.5vh;
}

.slogan-spec {

	.first-screen__slogan span:first-child {
		margin-top: 10px;
		transform: rotate(0);
	}

	.first-screen__time {
		margin-top: 65px;
	}
}


@media (max-width: 1024px) {

	.first-screen {

		&:before {
			display: none;
		}

		&:after {
			display: none;
		}

		&__title {
			font-size: 55px;
		}

		&__wrapper-logo-foto {
			flex-wrap: wrap;
			justify-content: center;
		}

		&__logo {
			margin-right: 0;
		}

		&__foto {
			height: auto;
			width: 100%;
		    max-width: 768px;
		    margin-top: 60px;

		    img {
		    	height: auto;
		    }
		}

		&__img {

	    	&--lg {
				display: none;
	    	}

	    	&--xs {
				display: block;
	    	}
    	}

	}

	.first-view-port {
		display: block;
		height: auto;
	}

}

@media (max-width: 768px) {
	.first-screen {

		&__wrapper {
			padding: 40px;
		}
		
		&__title {
			font-size: 44px;
		}

		&__middle {
			flex-wrap: wrap;
			justify-content: center;
		}

		&__logo {
			margin: 20px 0;
		}

		.trigger-form {
			margin: 20px 0;
		}
	}
}

@media (max-width: 480px) {

	.first-screen {

		&__wrapper {
			padding: 0;
		}

		&__title {
			font-size: 36px;
		}

	}

}