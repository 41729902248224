.popup {
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(33, 33, 33, 0.3);
	z-index: 2;
	transition: 0.2s;
	
	&--active {
		visibility: visible;
		opacity: 1;
	}
}

.popup-form {
	width: 280px;
	display: flex;
	flex-wrap: wrap;
	padding: 30px 30px;
	background-color: $crem;
	position: relative;

	&__input {
		width: 100%;
		margin: 10px 0;
		border: 1px solid $black;
	}

	&__button {
		width: 100%;
		margin: 10px 0 0 0;
		border: 1px solid $black;
		background-color: #fff;
	}

	&__trigger {
		width: 100%;
		margin: 0 0 10px 0;
		text-align: center;
		color: $black;
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 15px;
		transform: rotate(45deg);
		cursor: pointer;
		width: 20px;
		height: 20px;

		&:hover {
			&:before {
				background-color: $primary;
			}
			&:after {
				background-color: $primary;
			}
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
		    top: 9px;
		    left: 3px;
			width: 15px;
			height: 2px;
			background-color: $black;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
		    top: 9px;
		    left: 3px;
			width: 15px;
			height: 2px;
			background-color: $black;
			transform: rotate(90deg);
		}
	}
}