.header {
	display: flex;
	justify-content: center;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: $primary;
	z-index: 2;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 80px;
	}

	&__nav {
		width: 100%;
	}
}

.main-phones {
	max-width: 166px;
    width: 100%;

	&__item {
		width: 100%;
		line-height: 1.4;
		font-weight: 300;
		text-decoration: none;
	}
}

.main-menu {
	display: flex;
	justify-content: center;
	margin: 0;
    padding: 0;
	list-style: none;

	&__item {
		margin: 0 7.5px;
		padding: 5px 0 5px 0;
		overflow: hidden;
	}

	&__link {
		text-transform: uppercase;
		text-decoration: none;
		color: #fff;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $accent;
			transform: translateX(-102%);
			transition: 0.2s;
		}

		&:hover {
			
			&:before {
				transform: translateX(0);
			}
		}
	}
}

.main-phones {
    padding-left: 45px;
    position: relative;

    &:before {
    	content: "";
    	position: absolute;
    	top: 0;
    	bottom: 0;
    	left: 0;
    	margin: auto 0;
    	width: 35px;
    	height: 35px;
    	display: block;
    	background-size: 35px;
    	background-position: 0 center;
    	background-repeat: no-repeat;
		background-image: url("images/components/header/wassap-logo-button.svg");
    }
	
	&__item {
		color: #fff;
	}
}

@media (max-width: 1169px) {
	.main-phones {
		max-width: 125px;
	    padding-left: 0;

	    &:before {
	    	display: none;
	    }
	}

	.main-menu {
		justify-content: flex-end;

		&__item {
			
			&:last-child {
				margin-right: 0;
			}
		}	
	}

	.button--header {
		display: none;
	}
}


@media (max-width: 1024px) {
	.header__nav {
		display: none;
	}

	.button--header {
		display: block;
	}
} 

@media (max-width: 480px) {
	.button--header {
		padding: 7.5px 15px;
		font-size: 16px;
	}

}