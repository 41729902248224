.calculator {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: $crem;
	border-top: 1px solid darken($crem, 5%);

	&--info {
		border-top: 0;
		border-bottom: 1px solid darken($crem, 5%);
	}

	&__wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
     	padding: 60px 0 0 0;

     	&--info {
     		padding: 40px 0 100px 0
     	}
	}

	&__title {
		width: 100%;
		margin-bottom: 30px; 
		text-align: center;
	}

	&__margin-top40 {
		margin: 40px 0 0 0;
	}
}

.calc {
	display: flex;
	flex-wrap: wrap;

	&__row {
		display: flex;
		width: 100%;

		&--insurance {
			margin-top: 0;
		}
	}

	&__cell {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
    	margin: 0 35px 0 0;
    	padding: 20px 0;
    	border-bottom: 1px dotted darken($crem, 15%);

    	&--no-wrap {
    		flex-wrap: nowrap;
     		align-items: baseline;
    	}

    	&--border-bottom-no {
    		border-bottom: 0;
    	}

    	&--bold {
			font-weight: 600;
		}

		&--name {
			text-transform: uppercase;
			font-weight: 600;
		}
	}
	
	&__label {
		width: 100%;
		margin-bottom: 15px;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 600;

		&--checkbox {
			width: auto;
			position: relative;
			cursor: pointer;

			&:after {
				content: "";
			    position: absolute;
			    top: 2.5px;
			    right: -27.5px;
			    width: 15px;
			    height: 15px;
			    border: 2px solid darken($crem, 15%);
			    background-color: #fff;
			}
		}

		&--active-checkbox {

			&:before {
				content: "✔";
				position: absolute;
			    top: 1px;
			    right: -24px;
			    color: $primary;
			    z-index: 1;
			    height: 19px;
			    font-size: 16px;
			}
		}

		&--range {
			display: flex;
			justify-content: space-between;
		}

		&--radio {
			font-size: 16px;
		}
	}

	&__field {
		max-width: 280px;
		min-width: 200px;
		height: 40px;
		padding-left: 10px; 
		font-size: 16px;
		z-index: 1;

		&:focus {
			border: 1px solid $primary;
		}

		&--number {
			position: relative;
		}
	}

	&__select {
		max-width: 280px;
		min-width: 200px;
		height: 40px;
		padding-left: 10px; 
		font-size: 16px;
		z-index: 1;

		&:focus {
			border: 1px solid $primary;
		}
	}

	&__all-total-cost {
    	font-size: 34px;
    	font-weight: 600;
	}

	&__insurance-value {
		font-size: 34px;
    	font-weight: 600;
	}

	&__money-value {
		font-size: 34px;
    	font-weight: 600;
	}

	&__money-currency {
		padding: 0 0 0 5px;
		font-size: 18px;
	}

	&__range {
		-webkit-appearance: none;
	    width: 100%;
	    height: 12.5px;
	    outline: none;
	    border: 1px solid darken($crem, 15%);
	    border-radius: 17.5px;
	    margin: 15px 0 0 0;
	}

	&__checkbox {
		display: none;
	}
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid darken($crem, 15%);
  height: 35px;
  width: 35px;
  cursor: pointer;
  border-radius: 17.5px;
  background-color: $primary;
}
input[type=range]::-moz-range-thumb {
  border: 1px solid darken($crem, 15%);
  height: 35px;
  width: 35px;
  cursor: pointer;
}
input[type=range]::-ms-thumb {
  border: 1px solid darken($crem, 15%);
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.range-result {
	font-size: 14px;
}

.value-range {
	font-size: 20px;
	font-weight: 600;
	padding: 0 5px;
}

.calculator-info {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	&__title {
		margin-bottom: 35px;
	}

	&__ul {
		width: 100%;
		margin: 0;
		padding: 0 15px;
	}

	&__item {
    	font-size: 13px;
	    line-height: 1.4;
	    padding: 5px 0;
	    border-bottom: 1px dotted darken($crem, 15%);
	}
}

.spins {
	width: 20px;
	position: relative;
	margin-left: 5px;
	

	&__minus {
		width: 20px;
		height: 19px;
		position: absolute;
		top: 21px;
		cursor: pointer;
		border: 1px solid darken($crem, 15%);
		background-color: #fff;
		background-size: 11px;
	    background-position: center;
	    background-repeat: no-repeat;
		background-image: url("images/components/calculator/minus-button.svg");
	}

	&__plus {
		width: 20px;
		height: 19px;
		position: absolute;
		top: 0;
		cursor: pointer;
		border: 1px solid darken($crem, 15%);
		background-color: #fff;
		background-size: 11px;
	    background-position: center;
	    background-repeat: no-repeat;
		background-image: url("images/components/calculator/add-filled-cross-sign.svg");
	}
}

@media (max-width: 1024px) {
	.calc {

		$this: &;

		&__row {
			flex-direction: column;

			&:last-child {
				
				#{$this}__cell {
					border-bottom: 0;
				}
			}
		}

		&__cell {
			justify-content: center;
			margin: 0;
			text-align: center;

			&--lg {
				display: none;
			}

			&--flex-start-xs {
				justify-content: flex-start;
				text-align: left;
			}
		}

		&__label {

			&--radio {
				width: auto;
				margin: 15px 30px 5px 0;
			}

			&--checkbox {
				margin: 0;
			}
		}
	}
}