.portfolio {
	display: flex;
	justify-content: center;
	width: 100%;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
     	padding: 60px 0 100px 0;
	}

	&__title {
		width: 100%;
		margin-bottom: 70px; 
		text-align: center;
	}

	&__pic {
		display: flex;
    	justify-content: center;
    	align-items: center;
		width: calc(25% - 45px);
		height: 125px;
    	margin: 45px 0;
	}
}

@media (max-width: 1024px) {
	.portfolio {

		&__wrapper {
			flex-direction: column;
    		align-items: center;
		}

		&__pic {
			width: 250px;
		}
	}
}